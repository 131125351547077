module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#ed703a","display":"minimal-ui","icon":"src/images/LM-RGB22.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"9291cba8ea4ebf3a29c60bebea41420d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"lemanndi","accessToken":"MC5ZZDJNR3hJQUFDc0FwQXNX.77-9DVFSVHka77-977-9BO-_ve-_ve-_ve-_vW7vv70UCO-_vQfvv70L77-977-9Ee-_ve-_ve-_ve-_vTZdBQ","promptForAccessToken":true,"apiEndpoint":"https://lemanndi.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max","q":50},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
